// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { createPath } from 'react-router';

const urlMapping = {
    '/plesk/admin-panel=server.tools/': '/admin/server/tools',
};

export const useNavigateCallback = () => {
    const navigate = useNavigate();

    return useCallback(e => {
        if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
            return;
        }

        const url = e.target.closest('a').getAttribute('href');
        if (!require('routes').isClientSideRedirectAllowed(url)) {
            return;
        }

        e.preventDefault();
        navigate(url);
    }, [navigate]);
};

const Link = ({
    to = undefined,
    disabled = undefined,
    onClick = undefined,
    ...props
}) => {
    if (disabled) {
        to = undefined;
        onClick = undefined;
    }

    if (urlMapping[to]) {
        to = urlMapping[to];
    }

    if (typeof to === 'object' && to !== null) {
        to = createPath(to);
    }

    const handleClick = event => {
        if (onClick) {
            onClick(event);
        }

        if (event.defaultPrevented) {
            return;
        }

        if (!to) {
            event.preventDefault();
            return;
        }

        if (!require('routes').isClientSideRedirectAllowed(to)) {
            event.preventDefault();

            const features = [];
            if (typeof props.rel === 'string' && props.rel.length > 0) {
                features.push(props.rel.split(' '));
            }

            window.open(to, props.target || '_self', features.join(','));
        }
    };

    return <RouterLink to={to} onClick={handleClick} {...props} />;
};

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    target: PropTypes.string,
    rel: PropTypes.string,
};

export default Link;
